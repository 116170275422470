import React, { FC, useEffect, useContext, useRef } from 'react';
import cn from 'classnames';

import MobileMenuContext from '../../context';

import DefaultModal from '../modals';
import MenuSub from '../modals/sub';

export const MenuModal: FC = () => {
  const { currentModal } = useContext(MobileMenuContext);
  const modalInnerRef = useRef<HTMLDivElement>(null);
  const modals = {
    sub: <MenuSub />,
  };

  useEffect(() => {
    const latestTopOffset = window.scrollY;

    document.documentElement.classList.add('is-fixed');
    document.documentElement.scrollTop = latestTopOffset;
    document.body.scrollTop = latestTopOffset;

    return () => {
      document.documentElement.classList.remove('is-fixed');
      document.documentElement.scrollTop = latestTopOffset;
      document.body.scrollTop = latestTopOffset;
    };
  }, []);

  useEffect(() => modalInnerRef.current.scrollTo(0, 0), [currentModal]);

  return (
    <div className={cn(
      'mobile-menu__modal',
      { 'mobile-menu__modal_full': currentModal },
    )}>
      <div
        ref={modalInnerRef}
        className="mobile-menu__modal-inner"
      >
        {
          modals[currentModal] || <DefaultModal />
        }
      </div>
    </div>
  );
};
