import { TimelineMap } from './timelineMap';

let step: number;
let container: HTMLDivElement;
let years: Element[];
let prevIndex = 0;
let offset: number;
let header: HTMLElement;
let hero: HTMLElement;
let qr : HTMLElement;
let timeline: HTMLElement;
let card: HTMLElement;
let isSafari: boolean;

const scrollToYear = (yearIndex: number, dots: number, timeLineMap: TimelineMap) => {
  offset = header.getBoundingClientRect().height
      + qr.getBoundingClientRect().height
      + hero.getBoundingClientRect().height
      + timeline.getBoundingClientRect().height
      + timeline.getBoundingClientRect().top
      - (card.getBoundingClientRect().height)
      - window.innerHeight;

  window.scrollTo(0, dots ? offset + (step * dots) : offset - (window.innerHeight > 1500 ? 375 : 0)); // 375 - is right offset in cards line
  if (timeLineMap.currentYearIndex > yearIndex) {
    timeLineMap.goTo(yearIndex + 2);
  } else {
    timeLineMap.goTo(yearIndex);
  }
};

const handleClick = (e, timelineMap) => {
  if (e.target && e.target.dataset.after) {
    let selectedYearIndex = years.indexOf(e.target);
    let dots: number;

    if (prevIndex === selectedYearIndex) {
      return;
    }

    if (selectedYearIndex > 0) { // accumulate all duplicated years
      dots = years.slice(0, selectedYearIndex)
        .map( el => [...el.querySelectorAll('.circle')].length)
        .reduce((prev, curr) => prev + curr);

    } else {
      dots = 0;
    }

    prevIndex = selectedYearIndex;
    scrollToYear(selectedYearIndex, dots, timelineMap);
  }
};

const events = (timeLineMap) => {
  document.addEventListener('click', e => handleClick(e, timeLineMap));
  window.addEventListener('beforeunload', () => window.scrollTo(0, 0));
};

const init = (timelineMap) => {
  container = document.querySelector('.innovations');
  years = [...document.querySelectorAll('.map-point')];
  header = document.querySelector('header');
  qr = document.querySelector('.innovations__qr-code');
  hero = document.querySelector('.innovations__hero');
  timeline = document.querySelector('.innovations__timeline');
  card = document.querySelector('.innovations__timeline-card');
  isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (container && years.length) {
    step = (container.getBoundingClientRect().height / years.length)
        - (window.innerHeight > 1500 || isSafari && window.innerHeight < 950  ? 150 : 120);
    events(timelineMap);
  }
};

export default init;