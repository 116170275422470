import React, { FC, SyntheticEvent } from 'react';
import cn from 'classnames';

import Icon from '../../../denso-catalog/components/icon';

export const MenuItem: FC<{
  title: string,
  link: string,
  className?: string,
  isReverse?: boolean
  onClick?: (e: SyntheticEvent) => void,
}> = ({
  title,
  link,
  isReverse,
  className,
  onClick,
}) => {
  return (
    <div className={cn(
      'mobile-menu__link',
      { 'mobile-menu__link_reverse': isReverse },
    )}>
      <a
        href={link}
        className={`mobile-menu__link-text ${className}`}
        onClick={onClick}
      >{ title }</a>
      <div className="mobile-menu__link-icon">
        <Icon
          name="right-arrow"
          className="mobile-menu__link-svg"
        />
      </div>
    </div>
  );
};
