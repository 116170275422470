let densoSlider: HTMLDivElement;
let images: NodeListOf<HTMLDivElement>;
let textItems: NodeListOf<HTMLDivElement>;
let dotsItems: NodeListOf<HTMLButtonElement>;
let controlsWrapper: HTMLDivElement;

let currentSlide = 0;
let totalSlides = 0;
let isPreventActions = false;
let timerKeeper;
let delayValue = 5000; // 5 seconds between slide switch

const startTimer = (): void => {
  isPreventActions = false;

  clearTimeout(timerKeeper);

  [...dotsItems]
    .map((dot, index) => {
      dot.classList.toggle('denso-slider__dots-item_start', index === currentSlide);
    });

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  timerKeeper = setTimeout(calculateNextIndex, delayValue);
};

const switchSlider = (): void => {
  const currentTextItemSteps = textItems[currentSlide].querySelector('.denso-slider__slides-item-title').clientHeight / 50;

  densoSlider.style.setProperty(
    '--bottom-step-offset',
    ((currentTextItemSteps >= 3 ? currentTextItemSteps : 2) - 2).toString(),
  );

  [...dotsItems].map((dot, index) =>
    dot.classList.toggle('denso-slider__dots-item_active', index === currentSlide),
  );
  [...images].map((image, index) =>
    image.classList.toggle('denso-slider__images-item_active', index === currentSlide),
  );
  [...textItems].map((text, index) =>
    text.classList.toggle('denso-slider__slides-item_active', index === currentSlide),
  );

  // Disable click/touch actions until switch animation done
  isPreventActions = true;
  setTimeout(startTimer, 1000);
};

const calculateNextIndex = (isPrev = false): void => {
  currentSlide = isPrev
    ? currentSlide - 1
    : currentSlide + 1;

  currentSlide = currentSlide >= totalSlides
    ? 0
    : (
      currentSlide < 0
        ? totalSlides - 1
        : currentSlide
    );

  switchSlider();
};

const handleBtnClick = (button: HTMLButtonElement): void  => {
  calculateNextIndex(button.getAttribute('rel') === 'prev');
};

const catchOnButton = (e: MouseEvent): void => {
  const target = e.target as HTMLButtonElement;

  if (isPreventActions) {
    e.preventDefault();

    return;
  }

  if (target.classList.contains('denso-slider__arrows-item')) {
    handleBtnClick(target);
  }
};

const events = (): void => {
  controlsWrapper.addEventListener('click', catchOnButton);
};

const init = (): void => {
  densoSlider = document.querySelector('.denso-slider');

  if (densoSlider) {
    images = densoSlider.querySelectorAll('.denso-slider__images-item');
    textItems = densoSlider.querySelectorAll('.denso-slider__slides-item');
    dotsItems = densoSlider.querySelectorAll('.denso-slider__dots-item');
    controlsWrapper = densoSlider.querySelector('.denso-slider__controls');

    totalSlides = images.length;

    events();
    startTimer();
  }
};

export default {
  init,
};
