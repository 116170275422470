import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { TimelineMap } from './timelineMap';
import scrollYearsByClick from './scrollYearsByClick';

let timelineCards;
let sections;
let activeCard;
let timelineMap: TimelineMap;

function convertRange(value, r1, r2) {
  return ((value - r1[0]) * (r2[1] - r2[0])) / (r1[1] - r1[0]) + r2[0];
}

function handleActiveCardChange(prevActive) {
  if (typeof prevActive === 'number')
    sections[prevActive].classList.remove('active');
  sections[activeCard.value].classList.add('active');
}

const activeCardHandler: any = {
  set(obj, prop, value) {
    if (obj[prop] !== value) {
      let prevActive = obj[prop];
      obj[prop] = value;

      handleActiveCardChange(prevActive);

      const isSameYear =
        sections[prevActive].querySelector('.year').innerText ===
        sections[value].querySelector('.year').innerText;

      if (!isSameYear) {
        const step = value > prevActive ? 1 : -1;
        timelineMap.goTo(timelineMap.currentYearIndex + step);
      }
    }
    return true;
  },
};

activeCard = new Proxy({ value: 0 }, activeCardHandler);

const init = (): void => {
  if (document.querySelector('body').clientWidth < 1024) return;

  timelineCards = document.getElementById('timeline-cards') as HTMLDivElement;

  if (timelineCards) {
    gsap.registerPlugin(ScrollTrigger);

    sections = document.getElementsByClassName(
      'innovations__timeline-card',
    ) as HTMLCollectionOf<HTMLDivElement>;

    handleActiveCardChange(false);
    timelineMap = new TimelineMap(sections);

    const pinWrapWidth = timelineCards.offsetWidth;
    const horizontalScrollLength = pinWrapWidth - window.innerWidth;
    const factor = document.dir === 'rtl' ? -1 : 1;

    const tl = gsap.timeline({
      scrollTrigger: {
        pin: true,
        scrub: true,
        trigger: '#timeline',
        start: 'bottom bottom',
        onUpdate: event => {
          if (event.progress === 0) {
            activeCard.value = 0;
            return;
          }
          activeCard.value = Math.round(
            convertRange(event.progress, [0, 1], [0, sections.length - 1]),
          );
        },
        end: pinWrapWidth + window.innerWidth / 2 - 324,
      },
      defaults: { ease: 'none' },
    });

    tl.to(
      '#timeline-cards',
      {
        x: -horizontalScrollLength * factor,
      },
      0,
    );

    ScrollTrigger.refresh();

    // here is click event listener which scroll into selected year
    scrollYearsByClick(timelineMap);
  }
};

export default {
  init,
};
