let position = { left: 0, x: 0 };
let container: HTMLElement;
let items: HTMLElement[];

const mouseMoveHandler = (e): void => {
  container.scrollLeft = position.left - (e.clientX - position.x);
};

const mouseUpHandler = (): void => {
  container.style.cursor = 'grab';
  items.forEach(el => el.style.cursor = 'pointer');
  document.removeEventListener('mousemove', mouseMoveHandler);
};

const mouseDownHandler = (e) => {
  container.style.cursor = 'grabbing';
  items.forEach(el => el.style.cursor = 'grabbing');
  position = {
    left: container.scrollLeft,
    x: e.clientX,
  };
  document.addEventListener('mousemove', mouseMoveHandler);
};

const events = () => {
  container.addEventListener('mousedown', mouseDownHandler);
  items.forEach(item => {
    item.addEventListener('dragstart', (e) => e.preventDefault());
    item.style.userSelect = 'none';
  });
  document.addEventListener('mouseup', mouseUpHandler);
};

export const grabber = (wrapper: HTMLElement, elements: HTMLElement[]) => {
  if (wrapper && elements) {
    container = wrapper;
    items = elements;
    events();
  }
};

export default {
  grabber,
};