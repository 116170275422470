export const DEFAULT_LOCATION = {
  lat: 49.7674874,
  lng: 16.3592536,
};

export function getUserLocation(): Promise<google.maps.LatLng | null> {
  return new Promise(resolve => {
    function success(position) {
      resolve(
        new google.maps.LatLng(
          position.coords.latitude,
          position.coords.longitude,
        ),
      );
    }
    function error() {
      console.warn('Couldn\'t get user\'s location');
      resolve(null);
    }
    navigator.geolocation.getCurrentPosition(success, error);
  });
}
