let blitzContainer: HTMLElement;

const init = (): void => {
  blitzContainer = document.querySelector('.js-geo-message');
  if (blitzContainer) {
    document.addEventListener('afterBlitzInject', (event) => {
      if (!(<any>event).detail.params || !(<any>event).detail.params.includes('popupMessage')) {
        return;
      }
      const popupMessageOverlays = Array.from(document.querySelectorAll('.js-geo-message-overlay'));
      popupMessageOverlays.forEach((overlay) => {
        const popupMessageClose = document.querySelector('.js-geo-message-close');
        const popupMessageBlockUi = overlay.classList.contains('js-geo-message-overlay-block');
        const countryCode = overlay.dataset.detectedCountry;
        const geoMessageConfirmed = window.sessionStorage.getItem(`geo-message-${countryCode}`);
        if (geoMessageConfirmed && !popupMessageBlockUi) {
          return;
        }

        overlay.classList.remove('geo-message-overlay-hidden');
        popupMessageClose.addEventListener('click', () => {
          overlay.classList.add('geo-message-overlay-hidden');
          window.sessionStorage.setItem(`geo-message-${countryCode}`, 'true');
        });
      });
    });
  }
};

export default  {
  init,
};