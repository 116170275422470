import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';

import AddressField from './components/address-field';
import MainFilters from './components/main-filters';
import AdditionalFilters from './components/additional-filters';
import FilterTag from './components/filter-tag';
import storeStoresFilters from '../../store/filters/store-filters';
import LocationsNearby from './components/locations-nearby';
import storeCurrentFilters from '../../store/filters';
import { useWindowSize } from '../../helpers/windowSizeHook';
import { DESKTOP_WIDTH } from '../../constants';
import MainFilterCta from './components/main-filters-item/components/main-filter-cta';
import storeMap from '../../store/map';
import __ from '../../../../utils/translation';

interface SidebarProps {
  showMobileFilters: boolean;
  hideFilters: () => void;
}

const Sidebar: FC<SidebarProps> = props => {
  let showClearFilters = false;

  const windowSize = useWindowSize();

  for (const key in storeCurrentFilters.currentFilters) {
    if (!storeCurrentFilters.currentFilters.hasOwnProperty(key)) continue;
    if (storeCurrentFilters.currentFilters[key].length) showClearFilters = true;
  }

  function clearFilter(isSubfilter) {
    if (isSubfilter) {
      storeCurrentFilters.setCurrentFilters(
        [],
        storeStoresFilters.subfilterAlias,
      );
    } else {
      storeCurrentFilters.setCurrentFilters(
        [],
        storeStoresFilters.subfilterAlias,
      );
      storeCurrentFilters.setCurrentFilters([], storeStoresFilters.alias);
    }
  }

  const handleClose = () => {
    props.hideFilters();
  };

  const combinedTags = [
    {
      tags: storeCurrentFilters.currentFilters[storeStoresFilters.alias],
      alias: storeStoresFilters.alias,
    },
    {
      tags:
        storeCurrentFilters.currentFilters[storeStoresFilters.subfilterAlias],
      alias: storeStoresFilters.subfilterAlias,
    },
  ];

  const handleBack = () => {
    for (const entry of combinedTags.reverse()) {
      if (entry.tags.length) {
        storeCurrentFilters.setCurrentFilters([], entry.alias);
        break;
      } else {
        storeCurrentFilters.clearFilters();
      }
    }
  };

  let tagsLength = 0;
  combinedTags.forEach(entry => {
    tagsLength += entry.tags.length;
  });

  const filterTags = tagsLength ? (
    <div className="where-to-buy__sidebar-filter-tags">
      {combinedTags.map(entry => {
        return entry.tags.map(tag => {
          return (
            <FilterTag
              isSubfilter={entry.alias === storeStoresFilters.subfilterAlias}
              key={`${tag.id}${tag.label}`}
              onClose={clearFilter}
              filter={tag}
            />
          );
        });
      })}
    </div>
  ) : null;

  return (
    <div
      className={`where-to-buy__sidebar ${
        props.showMobileFilters ? 'active' : ''
      }`}
    >
      <div className="where-to-buy__sidebar-mobile-header">
        {__('Filters')}
        <div className="where-to-buy__close-button" onClick={handleClose} />
      </div>

      <div className="where-to-buy__sidebar-filters">
        {windowSize.width >= DESKTOP_WIDTH && (
          <div className="where-to-buy__sidebar-filter-label">{__('Address')}</div>
        )}
        {showClearFilters && windowSize.width >= DESKTOP_WIDTH && (
          <div
            onClick={storeCurrentFilters.clearFilters}
            className="where-to-buy__sidebar-clear-filters"
          >
            {__('Remove all filters')}
          </div>
        )}
        {windowSize.width >= DESKTOP_WIDTH && <AddressField />}
        {filterTags}
        {!!tagsLength && (
          <div className="where-to-buy__sidebar-mobile-filter-controls">
            <div onClick={handleBack} className="where-to-buy__sidebar-go-back">
              <MainFilterCta isSmall={true} />
              {__('Back')}
            </div>
            <div
              onClick={storeCurrentFilters.clearFilters}
              className="where-to-buy__sidebar-clear-filters"
            >
              {__('Remove all filters')}
            </div>
          </div>
        )}
        <MainFilters />
        <AdditionalFilters />
        {!storeMap.isLoading && <LocationsNearby />}
      </div>
    </div>
  );
};

export default observer(Sidebar);
