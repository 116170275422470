let header: HTMLElement;
let blitzContainer: HTMLElement;
let langDetectBanner: HTMLElement;
let dd: HTMLSelectElement;
let submit: HTMLButtonElement;
let closeButton: HTMLButtonElement;
let selectedCountry: string | null;

const langPrefix = (window as any).DENSO?.languageHandle ? `${(window as any).DENSO.languageHandle}` : 'eu';
const countries = (window as any).DENSO?.globalCountriesList || {};

const showBanner = () => {
  if (countries[langPrefix].flag !== langDetectBanner.dataset.detectedCountry) {
    langDetectBanner.classList.remove('lang-detect-container_closed');
  }
};

const toggleLang = () => {
  langDetectBanner.classList.add('lang-detect-container_closed');
  header.classList.remove('header_lang-detect-offset');
  sessionStorage.setItem('denso-selected-country', countries[dd.value].flag);
  (window as any).location.href = (window as any).DENSO?.primarySite
    ? `${(window as any).DENSO?.primarySite}${countries[dd.value].link}`
    : (window as any).location.href;
};

const submitProposedLanguage = () => {
  langDetectBanner.classList.add('lang-detect-container_closed');
  header.classList.remove('header__lang-detect-offset');
  sessionStorage.setItem('denso-selected-country', countries[langDetectBanner.dataset.detectedCountry].flag);
  (window as any).location.href = (window as any).DENSO?.primarySite
    ? `${(window as any).DENSO.primarySite}${countries[langDetectBanner.dataset.detectedLang].link}`
    : (window as any).location.href;
};

const closeBanner = () => {
  langDetectBanner.classList.add('lang-detect-container_closed');
  header.classList.remove('header_lang-detect-offset');
  sessionStorage.setItem('denso-selected-country', countries[langPrefix].flag);
};

const events = (): void => {
  submit.addEventListener('click', submitProposedLanguage);
  closeButton.addEventListener('click', closeBanner);
  dd.addEventListener('change', toggleLang);
};

const init = (): void => {
  blitzContainer = document.querySelector('.lang-banner');
  header = document.querySelector('.header');

  if (blitzContainer && header) {
    document.addEventListener('afterBlitzInjectAll', () => {

      header.classList.add('header_lang-detect-offset');
      langDetectBanner = document.querySelector('.lang-detect-container');
      dd = document.querySelector('.lang-detect__dd');
      submit = document.querySelector('.lang-detect__submit');
      closeButton = document.querySelector('.lang-detect__close');
      selectedCountry = sessionStorage.getItem('denso-selected-country');

      if (langDetectBanner && !selectedCountry && langDetectBanner.dataset?.detectedCountry.length) {
        showBanner();
        events();
      }
    });
  }
};

export default  {
  init,
};
