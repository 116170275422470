import React, { FC } from 'react';
import { Filter } from '../../../../store/filters/model';
import __ from '../../../../../../utils/translation';

interface CheckboxProps {
  name: string;
  value: Filter;
  id: string;
  label: string;
  onClick: (filter: Filter) => void;
}

const Checkbox: FC<CheckboxProps> = props => {
  const handleClick = () => {
    props.onClick(props.value);
  };

  return (
    <div className="additional-filter-checkbox">
      <input
        type="checkbox"
        name={props.name}
        id={props.id}
        onChange={handleClick}
        value={props.value.label}
      />
      <label htmlFor={props.id}>{__(props.label)}</label>
    </div>
  );
};

export default Checkbox;
