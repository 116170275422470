import React, { FC, useContext } from 'react';

import MobileMenuContext from '../../context';

import { MenuWrap, MenuItem } from '..';

const MenuSub: FC = () => {
  const { currentMenu } = useContext(MobileMenuContext);
  const { title = '', items = [] } = currentMenu;

  return (<MenuWrap title={ title }>
    {
      items.map((item, index) => <MenuItem
        key={`submenu-item-${index}`}
        title={item.title}
        link={item.url}
      />)
    }
  </MenuWrap>);
};

export default MenuSub;
