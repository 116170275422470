import __ from '../../utils/translation';

const anchorHashName = 'denso-next-feature';

const handleModalClick = (e) => {
  if ('nextFeatureClose' in e.target.dataset) {
    e.target.closest('.denso-modal').remove();
    document.documentElement.removeEventListener('click', handleModalClick);
  }
};

const handleClick = (e) => {
  const isMatchLink = (() => {
    const isLink = e.target.tagName === 'A';

    if (isLink) {
      const url = new URL(e.target.href);

      return url.hash.substring(1) === anchorHashName;
    }

    return false;
  })();

  if (isMatchLink) {
    const upcomingText = (window as any).DENSO?.upcomingFeatureModalText;
    const decodedText = upcomingText
      ? atob(upcomingText)
      : __('Feature is coming');

    e.preventDefault();

    document.body.insertAdjacentHTML('beforeend', `
      <div class="denso-modal">
        <div class="denso-modal__bg" aria-hidden="true" data-next-feature-close></div>
        <div class="denso-modal__content">
          <div class="denso-modal__close">
            <button
              type="button"
              class="close-button denso-modal__close-btn"
              data-next-feature-close
            >
              <svg class="close-button__icon">
                <use xlink:href="/dist/images/denso-sprite.svg#cross" />
            </svg>
            </button>
          </div>
          <div class="denso-modal__content-inner">
              <div class="text-content" style="text-align: center; white-space: break-spaces;">
                  <h2>${ decodedText }</h2>
              </div>
          </div>
        </div>
      </div>
    `);

    document.documentElement.addEventListener('click', handleModalClick);
  }
};

const events = () => {
  document.documentElement.addEventListener('click', handleClick);
};

const init = () => {
  events();
};

export default {
  init,
};
