let productWrapper: HTMLDivElement;
let productFilterForm: HTMLFormElement;

const buildSearchStr = (
  newFilter?: {
    key: string;
    val?: string;
  },
  isRemove?: boolean,
): void => {
  const formData = new FormData(productFilterForm);
  const queryUrl = new URLSearchParams();

  for (const item of formData.entries()) {
    queryUrl.append(item[0], item[1].toString());
  }

  if (newFilter) {
    if (isRemove) {
      queryUrl.delete(newFilter.key);
    } else {
      queryUrl.append(newFilter.key, newFilter.val);
    }
  }

  window.location.href =
        queryUrl.toString() === ''
          ? productFilterForm.action
          : `${window.location.hash.replace(/#.*/, '')}?${queryUrl.toString()}#safetyDataSheets`;
};

const checkBtnClick = target => {
  const availableClasses = [
    'downloads__filter-header-close',
    'downloads__filter-fixed-btn',
    'downloads__filter-bg',
  ];

  if (availableClasses.some(item => target.classList.contains(item))) {
    productWrapper.classList.toggle('downloads_open');
  }
};

const handleChange = (): void => {
  buildSearchStr();
};

const handleClick = (e): void => {
  const target = e.target;
  const isToggleTarget = { ...target.dataset }.hasOwnProperty('toggle');

  if (target.dataset.action) {
    const { key, val } = target.dataset;

    buildSearchStr(
      {
        key,
        val,
      },
      target.dataset.action === 'remove',
    );

    return;
  }

  // Clear all visible dropdowns
  const visibleDD = productFilterForm.querySelectorAll(
    '.downloads__dd_focus',
  );

  if (visibleDD.length) {
    visibleDD.forEach(item => {
      if (
        !(isToggleTarget && target.closest('.downloads__dd').isSameNode(item))
      ) {
        item.classList.remove('downloads__dd_focus');
      }
    });
  }

  if (isToggleTarget) {
    target.closest('.downloads__dd').classList.toggle('downloads__dd_focus');
  }

  checkBtnClick(target);
};

const events = (): void => {
  productFilterForm.addEventListener('change', handleChange);
  productWrapper.addEventListener('click', handleClick);
};

const init = (): void => {
  productWrapper = document.querySelector('.product-block__filters');

  if (productWrapper) {
    productFilterForm = document.querySelector(
      '.product-block__filter-form',
    );

    events();
  }
};

export default {
  init,
};
