import React, { FC, ReactNode, useContext } from 'react';

import MobileMenuContext from '../../context';
import Icon from '../../../denso-catalog/components/icon';

export const MenuWrap: FC<{
  title: string,
  children: ReactNode,
}> = ({
  title,
  children,
}) => {
  const { setCurrentModal, setCurrentMenu } = useContext(MobileMenuContext);

  const handleClose = () => {
    setCurrentModal(null);
    setCurrentMenu(null);
  };

  return (
    <div className="mobile-menu__modal-content">
      <header className="mobile-menu__modal-header">
        <div className="mobile-menu__modal-header-title">{ title }</div>
        <button
          className="mobile-menu__modal-header-close"
          type="button"
          onClick={handleClose}
        >
          <Icon
            name="cross"
            className="mobile-menu__modal-header-close-icon"
          />
        </button>
      </header>
      { children }
    </div>
  );
};
