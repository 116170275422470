import { grabber } from '../../scripts/utils/grabber';

const navigationWrapper: HTMLDivElement = document.querySelector('.navigation');
const navigationItems: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.navigation__link');
const categories: NodeListOf<HTMLDivElement> = document.querySelectorAll('.category-wrapper');
const container: HTMLUListElement = document.querySelector('.navigation__container');

const setActiveCategory = (e: Event) => {
  const target = e.target as HTMLElement;
  if (e.target && target.classList.contains('navigation__link')) {
    const slug = (target as HTMLAnchorElement).hash.substr(1);
    categories.forEach(el => el.classList.toggle('category-wrapper_active',
      el.dataset.attr === slug));
    navigationItems.forEach(el => el.classList.toggle('navigation__link_active',
      el.hash.substr(1) === slug));
  }
};

const events = () => {
  navigationWrapper.addEventListener('click', setActiveCategory);
  grabber(container, [...navigationItems]);
};

const init = () => {
  if (navigationWrapper) {
    events();
  }
};

export default {
  init,
};