import { FC } from 'react';

import { LanguageSelector } from '..';

import { root, item } from './index.module.scss';

export const FooterMenu: FC = () => (
  <div className={root}>
    <div className={item}>
      <LanguageSelector/>
    </div>
  </div>
);
