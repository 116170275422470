import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';

let entryPoint: HTMLButtonElement;
let langSwitch: HTMLDivElement;

const initApp = (): void => {
  const mobileMenu = entryPoint.dataset.mmenu
    ? JSON.parse(entryPoint.dataset.mmenu)
    : null;
  const availableLanguages = langSwitch.dataset.mlangs
    ? JSON.parse(langSwitch.dataset.mlangs)
    : null;

  langSwitch.removeAttribute('data-mlangs');

  ReactDOM.render(
    React.createElement(App, {
      mobileMenu,
      availableLanguages,
    }),
    entryPoint,
  );
};

const init = (): void => {
  entryPoint = document.querySelector('#denso-mobile-menu');
  langSwitch = document.querySelector('.lang-switch');

  if (entryPoint) {
    initApp();
  }
};

export default {
  init,
};
