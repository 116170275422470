import { grabber } from '../../scripts/utils/grabber';

let sectionCatcher: HTMLElement;
let stickyObserver: IntersectionObserver;
let sectionCatcherLinks: NodeListOf<HTMLAnchorElement>;
let sectionAnchors: NodeListOf<HTMLElement>;
let sectionsList: HTMLElement;
let inProcess: Boolean = false;

const redrawActive = () => {
  const items = [];
  let activeElement = null;

  sectionAnchors.forEach((item) => {
    const { top } = item.getBoundingClientRect();
    const offset = 40; // margin top - gap

    if ((top - offset) <= 0) {
      items.push(item);
    }
  });

  const isScrolledToEnd = Math.ceil(window.innerHeight + window.pageYOffset)
      >= document.body.scrollHeight;

  activeElement = items.length ? items.pop() : sectionAnchors[0];
  sectionCatcherLinks.forEach((link) => {
    link.classList.toggle(
      'section-catcher__item-link_active',
      link.hash === `#${activeElement.id}`);
  });

  if (sectionAnchors.length && isScrolledToEnd) {
    sectionCatcherLinks.forEach((link, idx) => {
      link.classList.toggle(
        'section-catcher__item-link_active',
        idx === (sectionCatcherLinks.length - 1),
      );
    });
  }

  inProcess = false;
};

const catchSections = () => {
  if (!inProcess) {
    requestAnimationFrame(redrawActive);
  }

  inProcess = false;
};

const initScrollSpy = () => {
  document.addEventListener('scroll', catchSections);
};

const catchSticky = (e: IntersectionObserverEntry[]): void => {
  sectionCatcher.classList.toggle('section-catcher_pinned', e[0].intersectionRatio < 1);
};

const initStickyObserver = (): void => {
  stickyObserver = new IntersectionObserver(catchSticky, { threshold: [1] });
  stickyObserver.observe(document.querySelector('.section-catcher__observe'));
};

const init = (): void => {
  sectionCatcher = document.querySelector('.section-catcher');

  if (sectionCatcher) {
    sectionCatcherLinks = sectionCatcher.querySelectorAll('.section-catcher__item-link');
    sectionAnchors = document.querySelectorAll('.product-block__anchor');
    sectionsList = sectionCatcher.querySelector('.section-catcher__list');

    initStickyObserver();
    initScrollSpy();
    grabber(sectionsList, [...sectionCatcherLinks]);
  }
};

export default {
  init,
};
