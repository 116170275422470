import React, { FC } from 'react';

interface MainFilterIconProps {
  filterId: number | string;
  isSubfilter: boolean;
}

const FILTER_ICON_MAP = {
  1: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -310)" fill="none" fillRule="evenodd">
        <path d="M24 310h24v24H24z" />
        <path
          fill="#009CDD"
          d="M27 329.72V331a1 1 0 001 1h1a1 1 0 001-1v-1h12v1a1 1 0 001 1h1a1 1 0 001-1v-1.28c.62-.35 1-1 1-1.72v-3a2 2 0 00-1.23-1.84l-1.37-4.1a3 3 0 00-2.84-2.06h-9.12a3 3 0 00-2.84 2.05l-1.37 4.1A2 2 0 0026 325v3a2 2 0 001 1.72zm2.5-1.72a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm13 0a1.5 1.5 0 110-3 1.5 1.5 0 010 3zm-11.06-9h9.12a1 1 0 01.95.68l1.1 3.32H29.4l1.1-3.32a1 1 0 01.95-.68z"
        />
        <path
          fill="#009CDD"
          d="M46 317.39v-2.11l-9.68-3.23a1 1 0 00-.66.01L26 315.57v2.13l10.02-3.64 9.98 3.33z"
        />
      </g>
    </svg>
  ),
  2: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -375)" fill="none" fillRule="evenodd">
        <path d="M24 375h24v24H24z" />
        <path
          fill="#DC0032"
          d="M28.91 378c-.74 0-1.36.47-1.59 1.12l-2.32 6.7v8.94c0 .62.5 1.12 1.12 1.12h1.11c.62 0 1.12-.5 1.12-1.11v-1.12h5.93a7.82 7.82 0 013.48-8.94H27.22l1.68-5.03h12.3l1.31 3.94c.69.07 1.36.24 2 .48l-1.73-4.98a1.68 1.68 0 00-1.58-1.12H28.9m11.74 7.82a.3.3 0 00-.3.24l-.2 1.47c-.34.15-.67.33-.96.53l-1.38-.56c-.13 0-.27 0-.35.14l-1.12 1.94a.28.28 0 00.07.36l1.19.91c-.05.37-.05.75 0 1.12l-1.19.92a.3.3 0 00-.07.36l1.12 1.93c.07.14.21.14.35.14l1.38-.56c.3.2.6.4.95.53l.22 1.48c.02.13.13.23.29.23h2.23c.13 0 .25-.1.27-.23l.21-1.48c.34-.14.64-.32.94-.53l1.38.56c.14 0 .29 0 .37-.14l1.11-1.93a.3.3 0 00-.06-.36l-1.2-.92c.02-.19.05-.37.05-.56 0-.19-.02-.37-.05-.56l1.19-.91a.3.3 0 00.06-.36l-1.12-1.93c-.06-.15-.2-.15-.35-.15l-1.38.56c-.3-.2-.6-.4-.95-.53l-.21-1.47a.26.26 0 00-.26-.24h-2.23m-11.74 1.12a1.68 1.68 0 110 3.35 1.68 1.68 0 010-3.35m12.86 2.8a1.68 1.68 0 110 3.35 1.68 1.68 0 010-3.35z"
        />
      </g>
    </svg>
  ),
  3: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -440)" fill="none" fillRule="evenodd">
        <path d="M24 440h24v24H24z" />
        <path
          fill="#3DBD5D"
          fillRule="nonzero"
          d="M44.77 457.63l-7.44-7.44a5.25 5.25 0 00-1.22-5.64 5.4 5.4 0 00-6.05-1.06l3.51 3.51-2.45 2.46-3.6-3.52A5.27 5.27 0 0028.6 452a5.25 5.25 0 005.64 1.23l7.44 7.43c.32.33.81.33 1.14 0l1.88-1.88c.41-.32.41-.9.08-1.14z"
        />
      </g>
    </svg>
  ),
};

const SUBFILTER_ICON_MAP = {
  1: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -374)" fill="none" fillRule="evenodd">
        <path d="M24 374h24v24H24z" />
        <path
          fill="#E8172B"
          fillRule="nonzero"
          d="M45 385h-1.59l1.3-1.29a1 1 0 10-1.42-1.42l-2.7 2.71h-2.18l2.3-2.29a1 1 0 10-1.42-1.42l-2.29 2.3v-2.18l2.71-2.7a1 1 0 10-1.42-1.42l-1.29 1.3V377a1 1 0 00-2 0v1.59l-1.29-1.3a1 1 0 10-1.42 1.42l2.71 2.7v2.18l-2.29-2.3a1 1 0 10-1.42 1.42l2.3 2.29h-2.18l-2.7-2.71a1 1 0 00-1.42 1.42l1.3 1.29H27a1 1 0 000 2h1.59l-1.3 1.29a1 1 0 000 1.42 1 1 0 001.42 0l2.7-2.71h2.18l-2.3 2.29a1 1 0 000 1.42 1 1 0 001.42 0l2.29-2.3v2.18l-2.71 2.7a1 1 0 001.42 1.42l1.29-1.3V395a1 1 0 002 0v-1.59l1.29 1.3a1 1 0 001.42 0 1 1 0 000-1.42l-2.71-2.7v-2.18l2.29 2.3a1 1 0 001.42 0 1 1 0 000-1.42l-2.3-2.29h2.18l2.7 2.71a1 1 0 001.42 0 1 1 0 000-1.42l-1.3-1.29H45a1 1 0 000-2z"
        />
      </g>
    </svg>
  ),
  2: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -439)" fill="none" fillRule="evenodd">
        <path d="M24 439h24v24H24z" />
        <path
          d="M46.94 446.37l.27.14.4.23-.76 1.35-.4-.23a6.15 6.15 0 00-4.66-.54l-.06.02.05.04c.08.07.17.14.24.22l.23.23a6.56 6.56 0 011.68 4.7c0 1.61-.48 2.83-1.26 3.7a3.57 3.57 0 01-2.28 1.2H40a3.54 3.54 0 01-3.03-2 6.48 6.48 0 01-.7-2.9c0-.7.1-1.53.4-2.4.33-.95.84-1.81 1.55-2.53l.15-.14-.08-.02c-.64-.14-1.35-.22-2.14-.24h-.4c-.75 0-1.43.06-2.05.2l-.07.01.1.1a6.9 6.9 0 012.12 4.73v.28c0 1.55-.42 2.84-1.25 3.72a3.65 3.65 0 01-2.65 1.19c-2.02 0-3.75-2.13-3.75-4.9 0-1.68.44-3.25 1.5-4.52.2-.24.41-.46.65-.67l.04-.04h-.01a4.9 4.9 0 00-1.06-.18l-.26-.01a7.83 7.83 0 00-3.35.72l-.65.31-.67-1.39.46-.23a8.8 8.8 0 014.2-.96c1 0 2 .25 2.92.7l.07.04.07-.03a9.4 9.4 0 013.27-.62h.38c1.6 0 3 .22 4.16.66l.08.04.07-.04a8.35 8.35 0 011.75-.59l.32-.06a7.73 7.73 0 014.52.58l.28.13zm-14.96 1.7l-.2.12c-.34.23-.64.5-.9.8a5.4 5.4 0 00-1.13 3.53c0 1.01.28 1.92.8 2.55.4.51.94.81 1.4.81 1.28 0 2.36-1.09 2.36-3.36a5.37 5.37 0 00-2.09-4.28l-.24-.18zm8.09.01l-.02.02c-.61.4-1.1.93-1.48 1.57a5.7 5.7 0 00-.76 2.86c0 .7.18 1.54.52 2.17.43.8 1.04 1.18 1.88 1.18.38 0 .9-.24 1.31-.7.53-.58.87-1.44.87-2.66 0-1.55-.43-2.76-1.25-3.63a4.4 4.4 0 00-1.04-.8h-.03z"
          fill="#EB1021"
          fillRule="nonzero"
        />
      </g>
    </svg>
  ),
  3: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -504)" fill="none" fillRule="evenodd">
        <path d="M24 504h24v24H24z" />
        <path
          fill="#E8172B"
          fillRule="nonzero"
          d="M27 513.18c-.02.4.2.75.55.92l7.1 3.25 3.25 7.1c.16.33.5.55.86.55h.06a.95.95 0 00.84-.65l5.3-16.1a.95.95 0 00-1.2-1.2l-16.11 5.29a.94.94 0 00-.65.84z"
        />
      </g>
    </svg>
  ),
  4: (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <g transform="translate(-24 -569)" fill="none" fillRule="evenodd">
        <path
          fill="#E8172B"
          fillRule="nonzero"
          d="M28.16 589h15.35a9.83 9.83 0 10-15.35 0zm6.45-13.54a1.23 1.23 0 112.47 0 1.23 1.23 0 01-2.47 0zm-6.15 2.46a1.23 1.23 0 112.45 0 1.23 1.23 0 01-2.45 0zm5.56 4.18c1.03-1.02 8-4.3 8-4.3s-3.27 6.98-4.29 8a2.62 2.62 0 11-3.71-3.7zm-6.79 1.97c0-.68.55-1.23 1.23-1.23.68 0 1.23.55 1.23 1.23 0 .69-.55 1.23-1.23 1.23-.68 0-1.23-.54-1.23-1.23zm7.38 0c0-.68.55-1.23 1.23-1.23.68 0 1.23.55 1.23 1.23 0 .69-.55 1.23-1.23 1.23-.68 0-1.23-.54-1.23-1.23zm7.38 0c0-.68.55-1.23 1.23-1.23.68 0 1.23.55 1.23 1.23 0 .69-.56 1.23-1.23 1.23-.68 0-1.23-.54-1.23-1.23z"
        />
        <path d="M24 569h24v24H24z" />
      </g>
    </svg>
  ),
};

function getSvgFromId(id: number | string, isSubfilter: boolean) {
  return isSubfilter ? SUBFILTER_ICON_MAP[id] : FILTER_ICON_MAP[id];
}

const MainFilterIcon: FC<MainFilterIconProps> = props => {
  const svg = getSvgFromId(props.filterId, props.isSubfilter);
  return <div className="main-filter-icon">{svg}</div>;
};

export default MainFilterIcon;
