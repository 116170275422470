import { createContext } from 'react';
import { IMobileMenuContent } from './model';

const MobileMenuContext = createContext<IMobileMenuContent>({
  currentModal: null,
  setCurrentModal: () => {},
  currentMenu: null,
  setCurrentMenu: () => {},
  mobileMenu: [],
  availableLanguages: [],
});

export default MobileMenuContext;
