import React, { FC, useState, useMemo } from 'react';
import cn from 'classnames';

import __ from '../../utils/translation';

import { IMobileMenu } from './model';
import MobileMenuContext from './context';
import { MenuModal } from './components';

const App: FC<IMobileMenu> = ({
  mobileMenu,
  availableLanguages,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const value = useMemo(() => ({
    currentModal,
    setCurrentModal,
    currentMenu,
    setCurrentMenu,
  } as {
    currentModal: string,
    setCurrentModal: (newModalName: string) => void
    currentMenu: any,
    setCurrentMenu: (newMenu: any) => void
  }), [currentModal]);

  const handleClick = () => setIsOpen(!isOpen);

  return (
    <MobileMenuContext.Provider value={{
      ...value,
      mobileMenu,
      availableLanguages,
    }}>
      <button
        className={cn(
          'header__toggle-menu-btn',
          { 'header__toggle-menu-btn_open': isOpen },
        )}
        type="button"
        onClick={handleClick}
      >
        <span className="header__toggle-menu-text" data-text={ __('Close') }>{ __('Open') }</span>
        <span className="header__toggle-menu-lines" />
      </button>

      {
        isOpen
          ? <MenuModal />
          : null
      }
    </MobileMenuContext.Provider>
  );
};

export default App;
