let downloadsWrapper: HTMLDivElement;
let downloadsFilterForm: HTMLFormElement;
// let downloadsVideo: NodeList;
let downloadsSortWrapper: NodeList;
let footer: HTMLElement;

const buildSearchStr = (
  newFilter?: {
    key: string;
    val?: string;
  },
  isRemove?: boolean,
): void => {
  const formData = new FormData(downloadsFilterForm);
  const queryUrl = new URLSearchParams();

  for (const item of formData.entries()) {
    queryUrl.append(item[0], item[1].toString());
  }

  if (newFilter) {
    if (isRemove) {
      queryUrl.delete(newFilter.key);
    } else {
      queryUrl.append(newFilter.key, newFilter.val);
    }

    // Clear subcategory too
    if (newFilter.key === 'cat' && isRemove) {
      queryUrl.delete('group');
    }
  }

  window.location.href =
    queryUrl.toString() === ''
      ? downloadsFilterForm.action
      : `${downloadsFilterForm.action}?${queryUrl.toString()}`;
};

const checkBtnClick = target => {
  const availableClasses = [
    'downloads__filter-header-close',
    'downloads__filter-fixed-btn',
    'downloads__filter-bg',
  ];

  if (availableClasses.some(item => target.classList.contains(item))) {
    downloadsWrapper.classList.toggle('downloads_open');
  }
};

// const youTubeParser = (url: string): string => {
//   const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
//   const match = url.match(regExp);
//
//   if (match && match[7].length === 11) {
//     return match[7];
//   }
//
//   return '';
// };

// const generateThumbs = (): void => { // currently not needed, but can be requested later
//   downloadsVideo.forEach(item => {
//     const videoLink = (item as HTMLElement).querySelector(
//       '.downloads__card-videolink',
//     ) as HTMLLinkElement;
//     const mediaBlock = (item as HTMLElement).querySelector(
//       '.downloads__card-media',
//     );
//     const videoId = youTubeParser(videoLink.href);
//
//     videoLink.dataset.videoId = videoId;
//
//     mediaBlock.insertAdjacentHTML(
//       'afterbegin',
//       `
//             <img
//                 class="downloads__card-media-img"
//                 src="https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg"
//                 loading="lazy"
//                 decoding="async"
//             />
//         `,
//     );
//   });
// };

const handleChange = (): void => {
  buildSearchStr();
};

const handleClick = (e): void => {
  const target = e.target;
  const isToggleTarget = { ...target.dataset }.hasOwnProperty('toggle');

  if (target.dataset.action) {
    const { key, val } = target.dataset;

    buildSearchStr(
      {
        key,
        val,
      },
      target.dataset.action === 'remove',
    );

    return;
  }

  // Clear all visible dropdowns
  const visibleDD = downloadsFilterForm.querySelectorAll(
    '.downloads__dd_focus',
  );

  if (visibleDD.length) {
    visibleDD.forEach(item => {
      if (
        !(isToggleTarget && target.closest('.downloads__dd').isSameNode(item))
      ) {
        item.classList.remove('downloads__dd_focus');
      }
    });
  }

  if (isToggleTarget) {
    target.closest('.downloads__dd').classList.toggle('downloads__dd_focus');
  }

  checkBtnClick(target);
};

const handleMobileShadows = () => {
  if (downloadsWrapper && footer && window.innerWidth < 768) {
    const offset = footer.getBoundingClientRect().top - window.innerHeight;

    downloadsWrapper.classList.toggle('downloads_hide-shadow', offset <= 0);
  }
};

const handleSortClick = (e) => {
  const target = e.target;

  if (target) {
    const { name, type } = target.dataset;

    if (name && type) {
      buildSearchStr({ key: name, val: type }, false);
    }
  }
};

const events = (): void => {
  downloadsFilterForm.addEventListener('change', handleChange);
  downloadsWrapper.addEventListener('click', handleClick);
  downloadsSortWrapper.forEach(el => el.addEventListener('click', handleSortClick));
  document.addEventListener('scroll', handleMobileShadows);
};

const init = (): void => {
  downloadsWrapper = document.querySelector('.downloads');
  downloadsSortWrapper = document.querySelectorAll('.downloads__sort-controllers');
  footer = document.querySelector('footer');

  if (downloadsWrapper) {
    downloadsFilterForm = downloadsWrapper.querySelector(
      '.downloads__filter-form',
    );
    // downloadsVideo = downloadsWrapper.querySelectorAll(
    //   '.downloads__card_video',
    // );

    events();

    // if (downloadsVideo.length) {
    //   generateThumbs();
    // }
  }
};

export default {
  init,
};
