let forms: NodeListOf<HTMLFormElement>;

const refreshTokenInputs = (token: string) => {
  forms.forEach((form) => {
    const csrfTokenInput = form.querySelector('input[name="CRAFT_CSRF_TOKEN"]') as HTMLInputElement;

    if (csrfTokenInput) {
      csrfTokenInput.value = token;
    }
  });
};

const init = () => {
  forms = document.querySelectorAll('form[data-freeform]');

  if (forms.length > 0) {
    fetch('/actions/blitz/csrf/json')
      .then(res => res.json())
      .then(({ token }) => refreshTokenInputs(token))
      .catch(err => console.error(err));
  }
};

export default {
  init,
};
