import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Filter } from '../../../../store/filters/model';
import MainFilterIcon from './components/main-filter-icon';
import MainFilterCta from './components/main-filter-cta';
import Tooltip from '../../../../../../components/tooltip';
import storeTooltips from '../../../../store/filters/tooltips';
import __ from '../../../../../../utils/translation';

interface MainFiltersItemProps {
  filter: Filter | string;
  handleClick: (filter: Filter | string) => void;
  isSubfilter: boolean;
}

const MainFiltersItem: FC<MainFiltersItemProps> = props => {
  if (!props.filter) return null;

  const toolTipText = (): unknown | null => {
    if (typeof props.filter !== 'string') {
      const { id } = props.filter;
      if (id === 2 && !props.isSubfilter) return storeTooltips.tooltips?.serviceNetwork;
      if (id === 3 && !props.isSubfilter) return storeTooltips.tooltips?.carRepairStation;
    }
    return null;
  };

  const handleClick = () => {
    props.handleClick(props.filter);
  };

  const label =
    typeof props.filter === 'string' ? props.filter : props.filter.label;
  const id = typeof props.filter === 'string' ? props.filter : props.filter.id;

  return (
    <div
      onClick={handleClick}
      className="where-to-buy__sidebar-main-filters-item"
    >
      <div className="column">
        <div className="where-to-buy__sidebar-main-filters-wrap">
            <MainFilterIcon filterId={id} isSubfilter={props.isSubfilter} />
            {__(label)}
            { toolTipText()
              ? <Tooltip className="column__hint">
              <span className="column__hint-info">
                <div dangerouslySetInnerHTML={{ __html: toolTipText() as string }}/>
              </span>
            </Tooltip>
              :  null
            }
        </div>
      </div>
      <div className="column">
        <MainFilterCta />
      </div>
    </div>
  );
};

export default observer(MainFiltersItem);
