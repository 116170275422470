import { ChangeEvent, FC, useCallback, useContext, useMemo } from 'react';

import MobileMenuContext from '../../context';

import { root, select, language } from './index.module.scss';

export const LanguageSelector: FC = () => {
  const { availableLanguages } = useContext(MobileMenuContext);
  const currentLanguage = useMemo(() => availableLanguages.find(lang => lang.isActive), [availableLanguages]);

  const handleChange = useCallback((e: ChangeEvent<HTMLSelectElement>) => {
    window.location.pathname = `/${e.target.value}`;
  }, []);

  if (availableLanguages.length === 0) {
    return null;
  }

  return (
    <div className={root}>
      <div className={language}>{currentLanguage.title}</div>

      <select
        className={select}
        value={currentLanguage.value}
        onChange={handleChange}
      >
        { availableLanguages.map(({ title, value }) => <option key={title} value={value} disabled={value === null}>{title}</option>) }
      </select>
    </div>
  );
};
