let locations: HTMLDivElement;
let locationsSVG: HTMLDivElement;
let locationsTooltip: HTMLDivElement;
let locationsMapFilter: HTMLDivElement;
const activeClass = 'locations__map-tooltip_active';

const tooltipHide = () => {
  locationsTooltip.classList.remove(activeClass);
};

const updateTooltipPosition = event => {
  locationsTooltip.style.cssText = `
        left: ${event.offsetX}px;
        top: ${event.offsetY}px;
    `;
};

const updateTooltipLabel = (label): void => {
  if (label) {
    locationsTooltip.innerHTML = label;
  }
};

const getArea = (event): void => {
  let currentElement = event.target;

  if (currentElement?.getAttribute('data-label')) {
    updateTooltipLabel(currentElement.getAttribute('data-label'));
    locationsTooltip.classList.add(activeClass);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    tooltipHide();
  }
};

const dehighlightAreas = () => {
  locationsSVG.classList.remove('locations__map-svg_active');
};

const highlightAreas = event => {
  let currentBtn = event.target;
  locationsSVG.classList.add('locations__map-svg_active');
  let targetColor = currentBtn
    ?.closest('.locations__map-filter-item')
    ?.getAttribute('data-color');

  if (currentBtn.tagName == 'A' && targetColor) {
    let areasList = locationsSVG.querySelectorAll('path');

    areasList.forEach(area => {
      let currentFill = area.getAttribute('fill');

      if (currentFill) {
        if (currentFill.toLowerCase() == targetColor.toLowerCase()) {
          area.classList.add('active-area');
        } else {
          area.classList.remove('active-area');
        }
      }
    });
  }
};

const scrollIntoView = (e: MouseEvent): void => {
  const anchor = (e.target as HTMLAnchorElement).getAttribute('href');

  if (anchor) {
    e.preventDefault();

    document
      .documentElement
      .querySelector(anchor)
      .scrollIntoView({
        behavior: 'smooth',
      });
  }
};

const events = (): void => {
  locationsSVG.addEventListener('mouseover', getArea);
  locationsSVG.addEventListener('mousemove', updateTooltipPosition);
  locationsSVG.addEventListener('mouseout', tooltipHide);
  locationsMapFilter.addEventListener('mouseover', highlightAreas);
  locationsMapFilter.addEventListener('mouseout', dehighlightAreas);
  locationsMapFilter.addEventListener('click', scrollIntoView);
};

const init = (): void => {
  locations = document.querySelector('.locations');

  if (locations) {
    locationsSVG = locations.querySelector('.locations__map-svg');
    locationsTooltip = locations.querySelector('.locations__map-tooltip');
    locationsMapFilter = locations.querySelector('.locations__map-filter');

    events();
  }
};

export default {
  init,
};
