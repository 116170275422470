import storeLocationFilters from '../store/filters/location-filters';
import storeProductGroupFilters from '../store/filters/product-group-filters';
import storeSalesFilters from '../store/filters/sales-filters';
import storeStoresFilters from '../store/filters/store-filters';
import storeCurrentFilters from '../store/filters';
import { FiltersResponse, getFilterSubtype } from '../api';
import storeTooltips from '../store/filters/tooltips';

export async function setStoreFilters(filters: FiltersResponse) {
  storeLocationFilters.setList(await filters.type_of_locations);
  storeProductGroupFilters.setList(await filters.product_group);
  storeSalesFilters.setList(await filters.type_of_sales);
  storeStoresFilters.setList(await filters.type_of_store);
  storeTooltips.setTooltips(await filters.tooltips);
}

export async function setDefaultFilter() {
  const urlParams = new URLSearchParams(window.location.search);
  const defaultFilterParam = urlParams.get('filter');
  const defaultSubfilterParam = urlParams.get('subfilter');
  const isRussian = (window as any).DENSO?.languageHandle === 'ru';
  const availableFilters = isRussian ? ['location', 'services', 'repair'] : ['location', 'services'];
  const availableSubfiltersFilters = ['remanufacturing', 'diesel', 'diagnostics'];
  const currentFilter = availableFilters.indexOf(defaultFilterParam);
  const currentSubFilter = availableSubfiltersFilters.indexOf(defaultSubfilterParam);

  if (currentFilter !== -1) {
    const locationToBuyFilter = storeStoresFilters.list[currentFilter];

    storeCurrentFilters.setCurrentFilters(
      [locationToBuyFilter],
      storeStoresFilters.alias,
    );

    const subfilters = await getFilterSubtype(currentFilter + 1);
    storeStoresFilters.setList(subfilters, true);

    if (currentSubFilter !== -1) {
      const subfilter = await storeStoresFilters.subfiltersList[currentSubFilter];
      storeCurrentFilters.setCurrentFilters(
        [subfilter],
        storeStoresFilters.subfilterAlias);
    }
  }
}
