function getYearsTable(innovationCards: HTMLCollectionOf<HTMLDivElement>) {
  const yearsTable = {};

  for (const card of Array.from(innovationCards)) {
    const year = card.querySelector('.year').innerHTML;
    if (yearsTable.hasOwnProperty(year)) {
      yearsTable[year] += 1;
    } else {
      yearsTable[year] = 1;
    }
  }

  return yearsTable;
}

export class TimelineMap {
  private progressElement: HTMLDivElement;

  private readonly yearsTable;

  private pointsElements: NodeListOf<HTMLDivElement>;

  public currentYearIndex = 1;

  constructor(innovationCards: HTMLCollectionOf<HTMLDivElement>) {
    this.yearsTable = getYearsTable(innovationCards);
    this.drawTimelinePoints();
    this.progressElement = document.querySelector('.map-progress');
    this.pointsElements = document.querySelectorAll('.map-point');
    this.goTo(this.currentYearIndex);
  }

  public goTo(yearIndex: number) {
    this.currentYearIndex = yearIndex;
    const point = this.pointsElements[yearIndex - 1];
    const pointRect = point.getBoundingClientRect();
    const pointWidth = this.pointsElements[0].offsetWidth;
    const progressWidth = document.dir === 'rtl'
      ? document.documentElement.clientWidth - pointRect.left
      : pointRect.left + pointWidth;
    this.progressElement.style.width = `${progressWidth}px`;
    this.updatePointClasses();
  }

  private updatePointClasses() {
    this.pointsElements.forEach((element, index) => {
      element.classList.remove('active');
      if (index < this.currentYearIndex) {
        element.classList.add('passed');
      } else {
        element.classList.remove('passed');
      }
    });

    if (this.pointsElements[this.currentYearIndex - 1]) {
      this.pointsElements[this.currentYearIndex - 1].classList.add('active');
    }
  }

  private drawTimelinePoints() {
    const timelineMapPoints = document.querySelector(
      '.map-points',
    ) as HTMLDivElement;

    for (let key in this.yearsTable) {
      const mapPoint = document.createElement('div');
      mapPoint.className = 'map-point';
      mapPoint.setAttribute('data-after', key);

      const circleWrapper = document.createElement('div');
      circleWrapper.className = 'circle-wrapper';
      if (this.yearsTable[key] > 1)
        circleWrapper.style.transform = 'translateX(calc(-50% + 2px))';

      for (let i = 0; i < this.yearsTable[key]; i++) {
        const circle = document.createElement('div');
        circle.className = 'circle';
        circleWrapper.appendChild(circle);
      }

      mapPoint.appendChild(circleWrapper);

      timelineMapPoints.appendChild(mapPoint);
    }
  }
}
