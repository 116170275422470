import React, { FC, memo, useContext, SyntheticEvent } from 'react';

import MobileMenuContext from '../../context';

import { MenuItem, FooterMenu } from '..';

const DefaultModal: FC = () => {
  const { setCurrentModal, setCurrentMenu, mobileMenu } = useContext(MobileMenuContext);
  const handleMenuClick = (menuItem) => (e: SyntheticEvent) => {
    if (menuItem.items?.length > 0) {
      e.preventDefault();

      setCurrentMenu(menuItem);
      setCurrentModal('sub');
    }
  };

  return (
    <>
      {
        mobileMenu.map((item) => (
          <MenuItem
            key={item.id}
            title={item.title}
            link={item.url}
            onClick={handleMenuClick(item)}
          />
        ))
      }

      <FooterMenu />
    </>
  );
};

export default memo(DefaultModal);