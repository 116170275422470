const header = document.querySelector('.header') as HTMLElement;
const headerSubNav = header.querySelector('.header__sub-nav') as HTMLElement;
let subnavState = false;
let currentNavItem;

const modalList = {
  'about': 'company',
  'products': 'other',
  'services': 'services',
};

const catchInitialHash = (): void => {
  const { hash } = window.location;
  const modalName = modalList[hash.substr(1)];

  if (modalName) {
    const currentModalLink = header.querySelector(`[data-dropdown="#header-modal-${modalName}"]`);

    (currentModalLink as HTMLAnchorElement)?.click();
  }
};

const toggleSubnav = (targetSubnav): void => {
  const headerTop = document.querySelector('.header__top') as HTMLElement;
  const headerH =
    document.documentElement.scrollTop > headerTop.offsetHeight
      ? headerTop
      : (document.querySelector('.header') as HTMLElement);
  const scrollbarWidth =
    window.innerWidth - document.documentElement.offsetWidth;

  headerSubNav.querySelectorAll('.header__sub-nav-item').forEach(item => {
    item.classList.remove('header__sub-nav-item_active');
  });
  headerSubNav.classList.add('header__sub-nav_active');

  if (targetSubnav) {
    targetSubnav.classList.add('header__sub-nav-item_active');
    headerSubNav.style.height = `${
      document.documentElement.offsetHeight - headerH.offsetHeight
    }px`;
    subnavState = true;
  } else {
    subnavState = false;
    headerSubNav.classList.remove('header__sub-nav_active');
  }

  const body = document.body;

  if (subnavState) {
    if (!body.classList.contains('is-fixed')) {
      body.style.paddingRight = `${scrollbarWidth}px`;
      body.classList.add('is-fixed');
    }
  } else {
    body.style.paddingRight = '';
    body.classList.remove('is-fixed');

    header
      .querySelector('.header__nav-link_active')
      .classList.remove('header__nav-link_active');
    currentNavItem?.classList.add('header__nav-link_active');
    currentNavItem = false;
  }
};

const catchNavLink = (e: Event): void => {
  const target = e.target as HTMLLinkElement;
  const targetDropdown = document.querySelector(
    target.getAttribute('data-dropdown'),
  ) as HTMLElement;

  if (target.hasAttribute('data-dropdown') && targetDropdown) {
    e.preventDefault();
    let currentActiveLink = header.querySelector('.header__nav-link_active');

    if (!currentNavItem && currentActiveLink) {
      currentNavItem = currentActiveLink;
    }

    currentActiveLink?.classList.remove('header__nav-link_active');

    target.classList.add('header__nav-link_active');
    toggleSubnav(targetDropdown);
  }

  if (target.classList.contains('header__sub-nav-close')) {
    e.preventDefault();
    toggleSubnav(false);
  }
};

function events() {
  document.addEventListener('click', catchNavLink, false);
}

const init = (): void => {
  if (headerSubNav) {
    events();
  }

  // Show modals by bash
  catchInitialHash();
};

export default {
  init,
};
